import { ApiService } from "@/services/api/ApiService";
import { TPayoutForm, TPayoutResponse } from "@/types/payout";
import { TBank } from "@/types/payment";

export class Payout extends ApiService {
  public info(tx: string) {
    return this.instance.get<TPayoutResponse>("paymentgate/payout/info/", {
      params: {
        tx,
      },
    });
  }

  public process(form: TPayoutForm) {
    return this.instance.post<TPayoutResponse>("paymentgate/payout/process/", {
      ...form,
      pan: form.pan?.replace(/\D+/g, ""),
    });
  }

  public banks(tx: string) {
    return this.instance.get<TBank[]>(`paymentgate/payout/${tx}/banks/`);
  }
}
