import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal__content change-sum" }
const _hoisted_3 = { class: "change-sum__amount" }
const _hoisted_4 = {
  key: 0,
  class: "change-sum_timing"
}

import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeSumModal',
  props: {
    amount: {}
  },
  emits: ["close"],
  setup(__props: any) {





const timer = ref(3);

onMounted(() => {
  const interval = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;
    } else {
      clearInterval(interval);
    }
  }, 1000);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "change-sum__title" }, "ВНИМАНИЕ!", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "change-sum__text" }, " Сумма вашего платежа была изменена, новая сумма к пополнению ", -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.amount), 1),
      _createVNode(VDivider),
      (timer.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Следующий шаг через " + _toDisplayString(timer.value) + " сек. ", 1))
        : (_openBlock(), _createBlock(VButton, {
            key: 1,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Подтверждаю")
            ])),
            _: 1
          }))
    ])
  ]))
}
}

})