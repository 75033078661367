<template>
  <div class="page">
    <img
      alt="zenpay"
      class="page__logo"
      height="24"
      src="/logo.svg"
      width="154"
    />
    <BaseContainer :support="isSupport" class="page__main">
      <TheLoader v-if="loading" />
      <!--      <CreateTestPayment v-else-if="status === EPayStatus.TEST" />-->
      <H2HP2PFlow
        v-else-if="status === EPayStatus.H2H_P2P"
        :request="paymentResponse"
        @change-wrapper="changeWrapper($event)"
      />
      <QRForm
        v-else-if="status === EPayStatus.QR"
        :request="paymentResponse"
        @fail="onFail"
        @success="onSuccess"
      />
      <PayForm
        v-else-if="status === EPayStatus.FORM"
        :request="paymentResponse"
        @fail="onFail"
        @process="onProcess"
      />
      <P2PForm
        v-else-if="status === EPayStatus.P2P_FORM"
        :request="paymentResponse"
        @fail="onFail"
        @process="onProcess"
      />
      <SBPFlow
        v-else-if="status === EPayStatus.SBP"
        @fail="onFail"
        @process="onProcess"
      />
      <PayoutForm
        v-else-if="status === EPayStatus.PAYOUT"
        :request="payoutResponse"
        @fail="onFail"
        @process="onProcess"
      />
      <CryptoPayoutForm
        v-else-if="status === EPayStatus.PAYOUT_CRYPTO"
        :request="payoutResponse"
        @fail="onFail"
        @process="onProcess"
      />
      <ProcessPayment
        v-else-if="status === EPayStatus.PROCESS"
        @fail="onFail"
        @success="onSuccess"
      />
      <OperationResult
        v-else-if="status === EPayStatus.SUCCESS || status === EPayStatus.FAIL"
        :status="status"
        :type="type"
        :url="url"
      />
    </BaseContainer>
  </div>
  <SvgSprite />
  <ChangeSumModal
    v-if="showSumChangeModal"
    :amount="newSum"
    @close="showSumChangeModal = false"
  />
  <Transition name="fade">
    <TheModal v-if="modal.isOpen" @hide="onClose">
      <template #title>
        <span v-if="modal.type === EModalType.TERMS">Terms</span>
        <span v-if="modal.type === EModalType.POLICY">Privacy policy</span>
      </template>
      <TermsContent v-if="modal.type === EModalType.TERMS" />
      <PolicyContent v-if="modal.type === EModalType.POLICY" />
    </TheModal>
  </Transition>
</template>

<script lang="ts" setup>
import { EModalType, EPayStatus } from "@/helpers/enums";
import { onMounted, ref } from "vue";
import { useModal } from "@/use/useModal";
import { usePayment } from "@/use/usePayment";
import PayForm from "@/components/form/PayForm.vue";
import SvgSprite from "@/components/SvgSprite.vue";
import TheModal from "@/components/TheModal.vue";
import TermsContent from "@/components/modal/TermsContent.vue";
import PolicyContent from "@/components/modal/PolicyContent.vue";
import TheLoader from "@/components/TheLoader.vue";
import BaseContainer from "@/components/BaseContainer.vue";
import OperationResult from "@/components/form/OperationResult.vue";
import ProcessPayment from "@/components/form/ProcessPayment.vue";
import PayoutForm from "@/components/form/PayoutForm.vue";
import CryptoPayoutForm from "@/components/form/CryptoPayoutForm.vue";
import P2PForm from "@/components/form/P2PForm.vue";
import H2HP2PFlow from "@/components/form/H2HP2P/H2HP2PFlow.vue";
import QRForm from "@/components/form/QRForm.vue";
import ChangeSumModal from "@/components/modal/ChangeSumModal.vue";
import eventBus from "@/helpers/eventBus";
import { type Handler } from "mitt";
import { usePrice } from "@/use/usePrice";
import SBPFlow from "@/components/form/SBPPayout/SBPFlow.vue";

const { modal, onClose } = useModal();

const {
  paymentInfo,
  loading,
  status,
  paymentResponse,
  payoutResponse,
  type,
  url,
} = usePayment();
const isSupport = ref(false);
const showSumChangeModal = ref(false);
const newSum = ref<string | null>("");

const onFail = () => (status.value = EPayStatus.FAIL);
const onSuccess = () => (status.value = EPayStatus.SUCCESS);
const onProcess = () => (status.value = EPayStatus.PROCESS);
const changeWrapper = (value: boolean) => (isSupport.value = value);
const handleShowChangeSumModal = ({
  amount,
  currency,
}: {
  amount?: number;
  currency: string;
}) => {
  newSum.value = usePrice(amount ?? 0, currency);
  showSumChangeModal.value = true;
};

onMounted(() => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  eventBus.on("showSumChangeModal", handleShowChangeSumModal as Handler);
  if (params.tx) {
    paymentInfo(params.tx);
  } else {
    loading.value = false;
  }
});
</script>

<style lang="scss">
@import "@scss/index.scss";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 32px 40px;

  @include mq($sz-mobile) {
    padding: 24px 16px;
  }

  &__logo {
    width: 154px;

    @include mq($sz-mobile) {
      width: 116px;
      margin-bottom: 35px;
    }
  }

  &__main {
    margin: auto;

    @include mq($sz-mobile) {
      flex-grow: 1;
      margin-block: 0;
    }
  }
}
</style>
