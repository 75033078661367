<template>
  <div class="modal">
    <div class="modal__content change-sum">
      <h1 class="change-sum__title">ВНИМАНИЕ!</h1>
      <p class="change-sum__text">
        Сумма вашего платежа была изменена, новая сумма к пополнению
      </p>
      <p class="change-sum__amount">{{ amount }}</p>
      <VDivider />
      <p v-if="timer" class="change-sum_timing">
        Следующий шаг через {{ timer }} сек.
      </p>
      <VButton v-else @click="$emit('close')">Подтверждаю</VButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import { onMounted, ref } from "vue";

defineProps<{
  amount: string;
}>();

defineEmits<{
  (e: "close"): void;
}>();

const timer = ref(3);

onMounted(() => {
  const interval = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;
    } else {
      clearInterval(interval);
    }
  }, 1000);
});
</script>

<style lang="scss" scoped>
@import "@scss/utils.scss";

.modal {
  position: fixed;
  inset: 0;
  display: flex;
  place-content: center;
  background-color: rgba(0 0 0 / 50%);
}

.change-sum {
  display: grid;
  gap: 24px;
  align-self: center;
  width: 100%;
  max-width: 400px;
  padding: 32px;
  border-radius: 20px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 12px 24px rgba(77, 77, 77, 0.08);

  &__title {
    @include font-template(Poppins, 32px, 40px, 600);
  }

  &__text {
    @include font-template(Roboto, 24px, 40px, 400);
  }

  &__amount {
    @include font-template(Poppins, 32px, 40px, 500);
    padding: 16px;
    border-radius: 10px;
    background-color: #daf6e3;
  }

  &__timing {
    @include font-template(Roboto, 14px, 20px, 600);
    color: #727272;
    letter-spacing: 0.3px;
  }
}
</style>
