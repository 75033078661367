<template>
  <operation-wrapper
    :price="price"
    has-cards-block
    :type="OperationType.PAYOUT"
  >
    <form class="form" @submit.prevent="handleOnSubmit">
      <div class="form__container form__container--payout">
        <input-wrapper
          :card-type="cardType"
          :condition="validFields.pan"
          class="form__input form__input--card"
        >
          <input-card
            v-model="form.pan"
            @input="validatePan(form.pan)"
            @update:card-type="cardType = $event"
          />
        </input-wrapper>
      </div>
      <v-divider class="divider-section" />
      <VButton
        v-if="request"
        :disabled="!isPayoutFormValid || disabled"
        :loading="isLoading"
        class="form__submit"
        type="submit"
      >
        Вывести {{ price }}
      </VButton>
    </form>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import { TCardTypes, TNullable } from "@/types/common";
import { TPayoutForm, TPayoutResponse } from "@/types/payout";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";

const props = defineProps({
  request: {
    type: Object as PropType<TPayoutResponse>,
    default: null,
  },
});

const emit = defineEmits(["process", "fail"]);

const price = computed(() =>
  usePrice(
    props.request?.tx.payout?.amount ?? 0,
    props.request?.tx.payout?.currency?.char_code
  )
);

const cardType = ref<TNullable<TCardTypes>>("unknown");
const form = reactive<TPayoutForm>({
  tx: props.request.tx.tx,
  pan: "",
  payment_method: props.request.tx.payout.means_of_payment_type.code as string,
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payout } = useApi();

const { validFields, isPayoutFormValid, validatePan } = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    await payout.process(form);
    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};
</script>
