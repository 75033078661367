import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--payout" }

import { TCardTypes, TNullable } from "@/types/common";
import { TPayoutForm, TPayoutResponse } from "@/types/payout";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PayoutForm',
  props: {
  request: {
    type: Object as PropType<TPayoutResponse>,
    default: null,
  },
},
  emits: ["process", "fail"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const price = computed(() =>
  usePrice(
    props.request?.tx.payout?.amount ?? 0,
    props.request?.tx.payout?.currency?.char_code
  )
);

const cardType = ref<TNullable<TCardTypes>>("unknown");
const form = reactive<TPayoutForm>({
  tx: props.request.tx.tx,
  pan: "",
  payment_method: props.request.tx.payout.means_of_payment_type.code as string,
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payout } = useApi();

const { validFields, isPayoutFormValid, validatePan } = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    await payout.process(form);
    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: price.value,
    "has-cards-block": "",
    type: _unref(OperationType).PAYOUT
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "form",
        onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(InputWrapper, {
            "card-type": cardType.value,
            condition: _unref(validFields).pan,
            class: "form__input form__input--card"
          }, {
            default: _withCtx(() => [
              _createVNode(InputCard, {
                modelValue: form.pan,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.pan) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(validatePan)(form.pan))),
                "onUpdate:cardType": _cache[2] || (_cache[2] = ($event: any) => (cardType.value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["card-type", "condition"])
        ]),
        _createVNode(VDivider, { class: "divider-section" }),
        (__props.request)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              disabled: !_unref(isPayoutFormValid) || disabled.value,
              loading: isLoading.value,
              class: "form__submit",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Вывести " + _toDisplayString(price.value), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})