import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "form-qr__wrapper"
}
const _hoisted_2 = ["href"]

import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { TPaymentResponse } from "@/types/payment";
import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import { usePrice } from "@/use/usePrice";
import { useApi } from "@/plugins/api";
import QrcodeVue from "qrcode.vue";
import TheLoader from "@/components/TheLoader.vue";
import eventBus from "@/helpers/eventBus";


export default /*@__PURE__*/_defineComponent({
  __name: 'QRForm',
  props: {
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
},
  emits: ["process", "fail", "success"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const price = computed(() =>
  usePrice(
    actualRequest.value?.info?.payment?.amount ?? 0,
    actualRequest.value?.info?.payment?.currency?.char_code
  )
);

const actualRequest = ref<TPaymentResponse>(props.request);
const { payment } = useApi();
const qr = ref<string | undefined>("");
const loading = ref(true);
const isFailState = computed(() =>
  [
    "unknown",
    "error",
    "reversal",
    "antifraud_error",
    "reversal_timeout",
    "provider_timeout",
  ].includes(actualRequest.value?.info?.payment?.status ?? "")
);

const getPaymentInfo = async () => {
  try {
    const { data } = await payment.info(actualRequest.value.info.tx);
    actualRequest.value = data;

    if (isFailState.value || !data.info.qr_data) {
      emit("fail");
    }
    if (data.info.payment.status === "success") {
      emit("success");
    }
  } catch (e) {
    console.error(e);
  }
};

let interval = 0;

const startPaymentInterval = () => {
  if (!interval) {
    interval = setInterval(getPaymentInfo, 5000);
  }
};

const process = async () => {
  try {
    loading.value = true;
    const { data } = await payment.processP2P({ tx: props.request.info.tx });
    actualRequest.value = data;
    qr.value = data.info.qr_data?.qr_url;
    if (
      data.info.payment.old_amount !== null &&
      data.info.payment.amount !== data.info.payment.old_amount
    ) {
      eventBus.emit("showSumChangeModal", {
        amount: data?.info?.payment?.amount,
        currency: data?.info?.payment?.currency?.char_code,
      });
    }
    startPaymentInterval();
  } catch (e) {
    qr.value = props.request?.info.qr_data?.qr_url;
    if (!qr.value) {
      emit("fail");
    }
  } finally {
    loading.value = false;
  }
};

onMounted(process);

onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: price.value,
    type: _unref(OperationType).PAYMENT,
    class: "form-qr"
  }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(QrcodeVue, {
              size: 260,
              value: qr.value
            }, null, 8, ["value"]),
            _createElementVNode("a", {
              href: qr.value,
              class: "button form-qr__button",
              target: "_blank"
            }, "Оплатить в приложении", 8, _hoisted_2)
          ]))
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})