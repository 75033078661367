import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--p2p sbp-form__container" }
const _hoisted_2 = { class: "sbp-form__requisites" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { key: 1 }

import { TPayoutForm, TPayoutResponse } from "@/types/payout";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import VDivider from "@/components/VDivider.vue";
import InputPhone from "@/components/inputs/InputPhone.vue";
import { TBank } from "@/types/payment";


export default /*@__PURE__*/_defineComponent({
  __name: 'SBPPayout',
  props: {
  request: {
    type: Object as PropType<TPayoutResponse>,
    default: null,
  },
  bank: {
    type: Object as PropType<TBank>,
    default: null,
  },
},
  emits: ["process", "fail"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const price = computed(() =>
  usePrice(
    props.request?.tx?.payout?.amount ?? 0,
    props.request?.tx?.payout?.currency?.char_code
  )
);
const url = computed(() => process.env.VUE_APP_API_URL);
const form = reactive<TPayoutForm>({
  tx: props.request.tx.tx,
  bank_code: props.bank?.code,
  account_number: "+7",
  payment_method: props.request.tx.payout.means_of_payment_type.code as string,
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payout } = useApi();

const { validFields, isSBPPayoutFormValid, validatePhone } = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    await payout.process(form);
    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "form sbp-form",
    onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (__props.bank?.logo2)
          ? (_openBlock(), _createElementBlock("picture", _hoisted_3, [
              _createElementVNode("img", {
                alt: __props.bank.title,
                src: `${url.value}${__props.bank.logo2}`
              }, null, 8, _hoisted_4)
            ]))
          : (__props.bank?.title)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(__props.bank.title), 1))
            : _createCommentVNode("", true)
      ]),
      _createVNode(InputWrapper, {
        condition: _unref(validFields).account_number,
        class: "form__input"
      }, {
        default: _withCtx(() => [
          _createVNode(InputPhone, {
            modelValue: form.account_number,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.account_number) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(validatePhone)(form.account_number)))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["condition"])
    ]),
    _createVNode(VDivider, { class: "divider-section" }),
    (__props.request)
      ? (_openBlock(), _createBlock(VButton, {
          key: 0,
          disabled: !_unref(isSBPPayoutFormValid) || disabled.value,
          loading: isLoading.value,
          class: "form__submit",
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Вывести " + _toDisplayString(price.value), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading"]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})