<template>
  <the-loader v-if="loading" />
  <form v-else class="form p2p-form" @submit.prevent="handleOnSubmit">
    <div class="form__container form__container--p2p">
      <b class="p2p-form__subtitle">Выберите банк</b>
      <ul v-if="banks.length" class="p2p-form__grid">
        <li v-for="bank in banks" :key="bank.id">
          <button class="p2p-form__bank" @click="handleOnSubmit(bank.id)">
            <picture>
              <img :alt="bank.title" :src="`${url}${bank.logo1}`" />
            </picture>
            <span>{{ bank.title }}</span>
          </button>
        </li>
      </ul>
      <span v-else>Нет доступных банков</span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import TheLoader from "@/components/TheLoader.vue";
import { computed, onMounted, PropType, ref } from "vue";
import type {
  TBank,
  TH2HP2PPaymentForm,
  TPaymentResponse,
} from "@/types/payment";
import { useApi } from "@/plugins/api";
import eventBus from "@/helpers/eventBus";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits<{
  (e: "updateRequest", value: TPaymentResponse): void;
}>();

const form = ref<TH2HP2PPaymentForm>({
  tx: props.request?.info?.tx,
  bank: -1,
});
const loading = ref(false);
const banks = ref<TBank[]>([]);
const url = computed(() => process.env.VUE_APP_API_URL);

const { payment } = useApi();
const handleOnSubmit = async (bankId: number | null) => {
  try {
    loading.value = true;
    form.value.bank = bankId;
    const { data } = await payment.processP2P(form.value);
    emit("updateRequest", data);
    if (
      data.info.payment.old_amount !== null &&
      data.info.payment.amount !== data.info.payment.old_amount
    ) {
      eventBus.emit("showSumChangeModal", {
        amount: data?.info?.payment?.amount,
        currency: data?.info?.payment?.currency?.char_code,
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const loadBanks = async () => {
  try {
    loading.value = true;
    const { data } = await payment.banks(props.request?.info?.tx);
    banks.value = data ?? [];

    if (!banks.value.length) {
      await handleOnSubmit(null);
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(loadBanks);
</script>

<style lang="scss">
@import "@scss/utils";

.p2p-form {
  &__subtitle {
    margin-bottom: 16px;
    color: $alter-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
    list-style: none;
  }

  &__bank {
    display: grid;
    justify-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    background-color: $wrapper-bg;

    > picture {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }

    > span {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
