import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import OperationWrapper from "@/components/OperationWrapper.vue";
import { ESBPPayoutStep, OperationType } from "@/helpers/enums";
import SBPBanks from "@/components/form/SBPPayout/SBPBanks.vue";
import SBPPayout from "@/components/form/SBPPayout/SBPPayout.vue";
import { usePayment } from "@/use/usePayment";
import { computed, ref } from "vue";
import { TBank } from "@/types/payment";
import { usePrice } from "@/use/usePrice";


export default /*@__PURE__*/_defineComponent({
  __name: 'SBPFlow',
  setup(__props) {

const { payoutResponse } = usePayment();

const price = computed(() =>
  usePrice(
    payoutResponse.value?.tx.payout?.amount ?? 0,
    payoutResponse.value?.tx.payout?.currency?.char_code
  )
);

const step = ref(ESBPPayoutStep.BANKS);
const bank = ref<TBank>();

const changeStep = (value: ESBPPayoutStep) => {
  step.value = value;
};

const changeBank = (value: TBank) => {
  bank.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: price.value,
    type: _unref(OperationType).PAYOUT
  }, {
    default: _withCtx(() => [
      (step.value === _unref(ESBPPayoutStep).BANKS)
        ? (_openBlock(), _createBlock(SBPBanks, {
            key: 0,
            request: _unref(payoutResponse),
            onSelectBank: changeBank,
            onChangeStep: changeStep
          }, null, 8, ["request"]))
        : (_openBlock(), _createBlock(SBPPayout, {
            key: 1,
            bank: bank.value,
            request: _unref(payoutResponse),
            onFail: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('fail'))),
            onProcess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('process')))
          }, null, 8, ["bank", "request"]))
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})