<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYMENT"
    has-cards-block
  >
    <form class="form" @submit.prevent="handleOnSubmit">
      <div class="form__container">
        <input-wrapper
          :card-type="cardType"
          :condition="validFields.pan"
          class="form__input form__input--card"
        >
          <input-card
            v-model="form.pan"
            @input="validatePan(form.pan)"
            @update:card-type="cardType = $event"
          />
        </input-wrapper>
        <input-wrapper
          :condition="validFields.expire"
          class="form__input form__input--expired"
        >
          <input-expired
            v-model="form.expire"
            @input="validateExpiry(form.expire)"
          />
        </input-wrapper>
        <input-wrapper
          :condition="validFields.cvv"
          class="form__input form__input--cvv"
        >
          <input-cvv v-model="form.cvv" @input="validateCVV(form.cvv)" />
        </input-wrapper>
        <input-wrapper
          :condition="validFields.holder"
          class="form__input form__input--holder"
        >
          <input-holder
            v-model="form.holder"
            @input="validateHolder(form.holder)"
          />
        </input-wrapper>
      </div>
      <v-divider class="divider-section" />
      <VButton
        v-if="request"
        :disabled="!isPayFormValid || disabled"
        :loading="isLoading"
        class="form__submit"
        type="submit"
      >
        Оплатить {{ price }}
      </VButton>
    </form>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import { TCardTypes, TNullable } from "@/types/common";
import { TPaymentForm, TPaymentResponse } from "@/types/payment";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputHolder from "@/components/inputs/InputHolder.vue";
import InputCvv from "@/components/inputs/InputCvv.vue";
import InputExpired from "@/components/inputs/InputExpired.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits(["process", "fail", "success"]);

const price = computed(() =>
  usePrice(
    props.request?.info?.payment?.amount ?? 0,
    props.request?.info?.payment?.currency?.char_code
  )
);

const cardType = ref<TNullable<TCardTypes>>("unknown");
const form = reactive<TPaymentForm>({
  tx: props.request.info.tx,
  pan: "",
  expire: "",
  cvv: "",
  holder: "",
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payment } = useApi();

const {
  validFields,
  isPayFormValid,
  validatePan,
  validateCVV,
  validateExpiry,
  validateHolder,
} = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    const { data } = await payment.pay(form);

    if (data.info.payment.status === "process" && data.redirect_url) {
      window.location.href = data.redirect_url;
      return;
    }

    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};
</script>
