import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--p2p" }
const _hoisted_2 = {
  key: 0,
  class: "p2p-form__grid"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { key: 1 }

import TheLoader from "@/components/TheLoader.vue";
import { computed, onMounted, PropType, ref } from "vue";
import { TPayoutResponse } from "@/types/payout";
import type { TBank } from "@/types/payment";
import { useApi } from "@/plugins/api";
import { ESBPPayoutStep } from "@/helpers/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'SBPBanks',
  props: {
  request: {
    type: Object as PropType<TPayoutResponse>,
    default: null,
  },
},
  emits: ["selectBank", "changeStep"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { payout } = useApi();
const url = computed(() => process.env.VUE_APP_API_URL);
const loading = ref(false);
const banks = ref<TBank[]>([]);

const selectBank = (bank: TBank) => {
  emit("selectBank", bank);
  emit("changeStep", ESBPPayoutStep.FORM);
};

const loadBanks = async () => {
  try {
    loading.value = true;
    const { data } = await payout.banks(props.request?.tx.tx);
    banks.value = data ?? [];
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

onMounted(loadBanks);

return (_ctx: any,_cache: any) => {
  return (loading.value)
    ? (_openBlock(), _createBlock(TheLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        class: "form p2p-form",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleOnSubmit && _ctx.handleOnSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("b", { class: "p2p-form__subtitle" }, "Выберите банк", -1)),
          (banks.value.length)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banks.value, (bank) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: bank.id
                  }, [
                    _createElementVNode("button", {
                      class: "p2p-form__bank",
                      onClick: ($event: any) => (selectBank(bank))
                    }, [
                      _createElementVNode("picture", null, [
                        _createElementVNode("img", {
                          alt: bank.title,
                          src: `${url.value}${bank.logo1}`
                        }, null, 8, _hoisted_4)
                      ]),
                      _createElementVNode("span", null, _toDisplayString(bank.title), 1)
                    ], 8, _hoisted_3)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, "Нет доступных банков"))
        ])
      ], 32))
}
}

})