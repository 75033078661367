<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYMENT"
    has-cards-block
  >
    <form class="form approve">
      <div class="form__container form__container--p2p">
        <p class="approve__head">Переведите деньги на эту карту:</p>
        <div class="approve__wrapper">
          <input-wrapper :card-type="cardType">
            <input-card
              v-model="cardNumber"
              @update:card-type="cardType = $event"
              disabled="true"
            />
          </input-wrapper>
          <button class="approve__copy" @click.prevent="copyToClipboard">
            <svg>
              <use xlink:href="#copy"></use>
            </svg>
          </button>
        </div>
        <span v-if="isCopied" class="approve__copied">Скопировано!</span>
        <VCountdown class="approve__countdown" :date-to="payExpire" />
      </div>
      <v-divider class="divider-section" />
      <VButton
        v-if="request"
        class="approve__submit"
        @click="$emit('complete')"
      >
        Проверить
      </VButton>
    </form>
  </operation-wrapper>
</template>

<script setup lang="ts">
import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import VCountdown from "@/components/VCountdown.vue";
import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import { computed, PropType, ref } from "vue";
import { TPaymentResponse } from "@/types/payment";
import { usePrice } from "@/use/usePrice";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import { TCardTypes, TNullable } from "@/types/common";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const price = computed(() =>
  usePrice(
    props.request?.info?.payment?.amount ?? 0,
    props.request?.info?.payment?.currency?.char_code
  )
);
const cardNumber = ref<string>(localStorage.getItem("p2p.card") || "");
const payExpire = ref<string>(localStorage.getItem("p2p.expires") || "");
const cardType = ref<TNullable<TCardTypes>>("unknown");
const isCopied = ref(false);

const copyToClipboard = async () => {
  await navigator.clipboard.writeText(cardNumber.value);
  isCopied.value = true;
  setTimeout(() => (isCopied.value = false), 2000);
};
</script>

<style lang="scss">
@import "@scss/utils";
.approve {
  &__head {
    margin-bottom: 5px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  &__copy {
    svg {
      width: 20px;
      height: 20px;
    }

    &:hover svg {
      fill: $btn-bg-main;
    }
  }

  &__copied {
    justify-self: center;
    color: $input-text-placeholder;
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__countdown {
    justify-self: center;
  }

  &__submit {
    margin-bottom: 26px !important;
  }
}
</style>
