import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container" }

import { TCardTypes, TNullable } from "@/types/common";
import { TPaymentForm, TPaymentResponse } from "@/types/payment";
import { computed, PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputHolder from "@/components/inputs/InputHolder.vue";
import InputCvv from "@/components/inputs/InputCvv.vue";
import InputExpired from "@/components/inputs/InputExpired.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PayForm',
  props: {
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
},
  emits: ["process", "fail", "success"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const price = computed(() =>
  usePrice(
    props.request?.info?.payment?.amount ?? 0,
    props.request?.info?.payment?.currency?.char_code
  )
);

const cardType = ref<TNullable<TCardTypes>>("unknown");
const form = reactive<TPaymentForm>({
  tx: props.request.info.tx,
  pan: "",
  expire: "",
  cvv: "",
  holder: "",
});
const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const { payment } = useApi();

const {
  validFields,
  isPayFormValid,
  validatePan,
  validateCVV,
  validateExpiry,
  validateHolder,
} = useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    const { data } = await payment.pay(form);

    if (data.info.payment.status === "process" && data.redirect_url) {
      window.location.href = data.redirect_url;
      return;
    }

    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: price.value,
    type: _unref(OperationType).PAYMENT,
    "has-cards-block": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "form",
        onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(InputWrapper, {
            "card-type": cardType.value,
            condition: _unref(validFields).pan,
            class: "form__input form__input--card"
          }, {
            default: _withCtx(() => [
              _createVNode(InputCard, {
                modelValue: form.pan,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.pan) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(validatePan)(form.pan))),
                "onUpdate:cardType": _cache[2] || (_cache[2] = ($event: any) => (cardType.value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["card-type", "condition"]),
          _createVNode(InputWrapper, {
            condition: _unref(validFields).expire,
            class: "form__input form__input--expired"
          }, {
            default: _withCtx(() => [
              _createVNode(InputExpired, {
                modelValue: form.expire,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.expire) = $event)),
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_unref(validateExpiry)(form.expire)))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["condition"]),
          _createVNode(InputWrapper, {
            condition: _unref(validFields).cvv,
            class: "form__input form__input--cvv"
          }, {
            default: _withCtx(() => [
              _createVNode(InputCvv, {
                modelValue: form.cvv,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((form.cvv) = $event)),
                onInput: _cache[6] || (_cache[6] = ($event: any) => (_unref(validateCVV)(form.cvv)))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["condition"]),
          _createVNode(InputWrapper, {
            condition: _unref(validFields).holder,
            class: "form__input form__input--holder"
          }, {
            default: _withCtx(() => [
              _createVNode(InputHolder, {
                modelValue: form.holder,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((form.holder) = $event)),
                onInput: _cache[8] || (_cache[8] = ($event: any) => (_unref(validateHolder)(form.holder)))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["condition"])
        ]),
        _createVNode(VDivider, { class: "divider-section" }),
        (__props.request)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              disabled: !_unref(isPayFormValid) || disabled.value,
              loading: isLoading.value,
              class: "form__submit",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Оплатить " + _toDisplayString(price.value), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})