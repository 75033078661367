import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputPhone',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const validateNumbers = (e: KeyboardEvent | ClipboardEvent, value: string) => {
  if (/^[+\d]+$/.test(value)) return true;
  else e.preventDefault();
};

const handleOnPaste = (e: ClipboardEvent) =>
  validateNumbers(e, (e.clipboardData as DataTransfer).getData("text"));

const handleOnKeyPress = (e: KeyboardEvent) =>
  validateNumbers(e, String.fromCharCode(e.keyCode));

const handleOnChange = () => {
  value.value = value.value.replace(/[^+\d]/g, "");
};

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    class: "input",
    maxlength: "12",
    placeholder: "Введите номер телефона",
    type: "tel",
    onInput: handleOnChange,
    onKeypress: handleOnKeyPress,
    onPaste: handleOnPaste
  }, null, 544)), [
    [_vModelText, value.value]
  ])
}
}

})