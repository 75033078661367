<template>
  <operation-wrapper :price="price" :type="OperationType.PAYOUT">
    <SBPBanks
      v-if="step === ESBPPayoutStep.BANKS"
      :request="payoutResponse"
      @select-bank="changeBank"
      @change-step="changeStep"
    />
    <SBPPayout
      v-else
      :bank="bank"
      :request="payoutResponse"
      @fail="$emit('fail')"
      @process="$emit('process')"
    />
  </operation-wrapper>
</template>

<script lang="ts" setup>
import OperationWrapper from "@/components/OperationWrapper.vue";
import { ESBPPayoutStep, OperationType } from "@/helpers/enums";
import SBPBanks from "@/components/form/SBPPayout/SBPBanks.vue";
import SBPPayout from "@/components/form/SBPPayout/SBPPayout.vue";
import { usePayment } from "@/use/usePayment";
import { computed, ref } from "vue";
import { TBank } from "@/types/payment";
import { usePrice } from "@/use/usePrice";

const { payoutResponse } = usePayment();

const price = computed(() =>
  usePrice(
    payoutResponse.value?.tx.payout?.amount ?? 0,
    payoutResponse.value?.tx.payout?.currency?.char_code
  )
);

const step = ref(ESBPPayoutStep.BANKS);
const bank = ref<TBank>();

const changeStep = (value: ESBPPayoutStep) => {
  step.value = value;
};

const changeBank = (value: TBank) => {
  bank.value = value;
};
</script>

<style lang="scss"></style>
