<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYMENT"
    has-cards-block
  >
    <form class="form" @submit.prevent="handleOnSubmit">
      <div class="form__container form__container--p2p">
        <input-wrapper
          :card-type="cardType"
          :condition="validFields.pan"
          class="form__input form__input--card"
        >
          <input-card
            v-model="form.pan"
            @input="validatePan(form.pan)"
            @update:card-type="cardType = $event"
          />
        </input-wrapper>
      </div>
      <v-divider class="divider-section" />
      <VButton
        v-if="request"
        :disabled="!isP2PFormValid || disabled"
        :loading="isLoading"
        class="form__submit"
        type="submit"
      >
        Продолжить
      </VButton>
    </form>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import OperationWrapper from "@/components/OperationWrapper.vue";
import { OperationType } from "@/helpers/enums";
import { computed, PropType, reactive, ref } from "vue";
import { TPaymentForm, TPaymentResponse } from "@/types/payment";
import { usePrice } from "@/use/usePrice";
import InputCard from "@/components/inputs/InputCard.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import { TCardTypes, TNullable } from "@/types/common";
import { useApi } from "@/plugins/api";
import { useValidation } from "@/use/useValidation";
import VButton from "@/components/VButton.vue";
import VDivider from "@/components/VDivider.vue";
import eventBus from "@/helpers/eventBus";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits(["process", "fail", "success"]);

const price = computed(() =>
  usePrice(
    props.request?.info?.payment?.amount ?? 0,
    props.request?.info?.payment?.currency?.char_code
  )
);

const cardType = ref<TNullable<TCardTypes>>("unknown");

const form = reactive<TPaymentForm>({
  tx: props.request.info.tx,
  pan: "",
});

const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const { payment } = useApi();

const { validFields, isP2PFormValid, validatePan } = useValidation();

const handleOnSubmit = async () => {
  isLoading.value = true;

  try {
    const { data } = await payment.payP2P(form);
    if (data.info.payment.amount !== data.info.payment.old_amount) {
      eventBus.emit("showSumChangeModal", {
        amount: data?.info?.payment?.amount,
        currency: data?.info?.payment?.currency?.char_code,
      });
    }
    if (data?.info?.p2p_card_data) {
      localStorage.setItem(
        "p2p.card",
        data.info.p2p_card_data.target_card_number
      );
      localStorage.setItem("p2p.expires", data.info.p2p_card_data.valid_till);
    }
    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};
</script>
