<template>
  <operation-wrapper
    :price="price"
    :type="OperationType.PAYMENT"
    class="form-qr"
  >
    <the-loader v-if="loading" />
    <div v-else class="form-qr__wrapper">
      <QrcodeVue :size="260" :value="qr" />
      <a :href="qr" class="button form-qr__button" target="_blank"
        >Оплатить в приложении</a
      >
    </div>
  </operation-wrapper>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { TPaymentResponse } from "@/types/payment";
import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import { usePrice } from "@/use/usePrice";
import { useApi } from "@/plugins/api";
import QrcodeVue from "qrcode.vue";
import TheLoader from "@/components/TheLoader.vue";
import eventBus from "@/helpers/eventBus";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits(["process", "fail", "success"]);

const price = computed(() =>
  usePrice(
    actualRequest.value?.info?.payment?.amount ?? 0,
    actualRequest.value?.info?.payment?.currency?.char_code
  )
);

const actualRequest = ref<TPaymentResponse>(props.request);
const { payment } = useApi();
const qr = ref<string | undefined>("");
const loading = ref(true);
const isFailState = computed(() =>
  [
    "unknown",
    "error",
    "reversal",
    "antifraud_error",
    "reversal_timeout",
    "provider_timeout",
  ].includes(actualRequest.value?.info?.payment?.status ?? "")
);

const getPaymentInfo = async () => {
  try {
    const { data } = await payment.info(actualRequest.value.info.tx);
    actualRequest.value = data;

    if (isFailState.value || !data.info.qr_data) {
      emit("fail");
    }
    if (data.info.payment.status === "success") {
      emit("success");
    }
  } catch (e) {
    console.error(e);
  }
};

let interval = 0;

const startPaymentInterval = () => {
  if (!interval) {
    interval = setInterval(getPaymentInfo, 5000);
  }
};

const process = async () => {
  try {
    loading.value = true;
    const { data } = await payment.processP2P({ tx: props.request.info.tx });
    actualRequest.value = data;
    qr.value = data.info.qr_data?.qr_url;
    if (
      data.info.payment.old_amount !== null &&
      data.info.payment.amount !== data.info.payment.old_amount
    ) {
      eventBus.emit("showSumChangeModal", {
        amount: data?.info?.payment?.amount,
        currency: data?.info?.payment?.currency?.char_code,
      });
    }
    startPaymentInterval();
  } catch (e) {
    qr.value = props.request?.info.qr_data?.qr_url;
    if (!qr.value) {
      emit("fail");
    }
  } finally {
    loading.value = false;
  }
};

onMounted(process);

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style lang="scss">
.form-qr {
  @import "@scss/utils.scss";
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 15px;

    @include mq($sz-mobile) {
      row-gap: 24px;
    }
  }
}
</style>
